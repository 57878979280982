.template-block-810 {
    display: flex;
    flex-direction: column;
    .t-810-sucess-message {
        color: white;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.5;
    }
    .t-810-label {
        color: white;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 0px 0px 5px;
        margin-top: 30px;
    }
    .t-810-currency {
        color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding-top: 5px;
    }
    .t-810-amount {
        background: transparent;
        border-color: rgb(47, 47, 47);
        border-style: solid;
        border-width: 1px;
        color: white;
        font-size: 16px;
        max-width: 100%;
        outline: none;
        padding: 10px;
    }
    .t-810-email {
        background: transparent;
        border-color: rgb(47, 47, 47);
        border-style: solid;
        border-width: 1px;
        color: white;
        font-size: 16px;
        max-width: 100%;
        outline: none;
        padding: 10px;
    }
    .t-810-error {
        color: red;
        font-size: 14px;
    }
    .t-810-monthly-wrap {
        cursor: pointer;
        color: white;
        margin-top: 30px;
        .t-810-monthly {
            margin-right: 10px;
        }
    }
    .t-810-button {
        background: white;
        border-color: rgb(0, 0, 0, 0);
        border-style: solid;
        border-width: 1px;
        color: black;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: normal;
        margin-top: 30px;
        max-width: 100%;
        padding: 10px 25px;
        text-align: center;
        transition: $mo-ease;
        width: auto;
        &:hover {
            background: black;
            border-color: white;
            color: white;
        }
        &.disabled {
            pointer-events: none;
        }
    }
    .t-810-secure {
        color: white;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding-top: 5px;
    }
    &.light {
        .t-810-amount,
        .t-810-currency,
        .t-810-email,
        .t-810-label,
        .t-810-monthly-wrap,
        .t-810-secure,
        .t-810-sucess-message {
            color: black;
        }
        .t-810-button {
            background: black;
            color: white;
            &:hover {
                background: white;
                border-color: black;
                color: black;
            }
        }
    }
    .t-810-block1 {
        background-color: #ffb8e0;
        border-radius: 12px;
        padding: 30px;
        .t-810-block1-period {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            .t-810-period-choice {
                color: white;
                cursor: pointer;
                font-size: 24px;
                font-weight: bold;
                margin: 0 10px;
                text-transform: uppercase;
                &.active,
                &:hover {
                    text-decoration-color: #f8f266;
                    text-decoration-line: underline;
                    text-decoration-thickness: 4px;
                    text-underline-offset: 4px;
                }
            }
        }
        .t-810-block1-amount-choices {
            align-items: center;
            display: flex;
            justify-content: center;
            .t-810-block1-button {
                background-color: #f8f266;
                border-radius: 5px;
                color: #f76c04;
                font-size: 20px;
                font-weight: bold;
                margin: 10px;
                padding: 20px;
                &.active {
                    border: 3px solid #f76c04;
                }
            }
        }
        .t-810-block1-custom-amount {
            .t-810-label {
                color: #f76c04;
                display: block;
                font-weight: bold;
            }
            .t-810-block1-input-wrapper {
                display: inline-block;
                position: relative;
                width: 100%;
                .t-810-amount {
                    background-color: white;
                    border-radius: 5px;
                    border: 1px solid #f76c04;
                    color: #f76c04;
                    font-size: 16px;
                    padding-right: 24px;
                    width: 100%;
                }
                .t-810-currency-symbol {
                    color: #f26522;
                    pointer-events: none;
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .t-810-block2 {
        background-color: #f8f266;
        border-radius: 12px;
        margin-top: 20px;
        padding: 30px;
        .t-810-title {
            color: #f76c04;
            display: block;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
        }
        .t-810-label {
            color: #f76c04;
            display: block;
            font-weight: bold;
        }
        .t-810-email {
            background-color: white;
            border-radius: 5px;
            border: 1px solid #f76c04;
            color: #f76c04;
            font-size: 16px;
            padding-right: 24px;
            width: 100%;
        }
        .t-810-button {
            background: white;
            border-color: rgb(0, 0, 0, 0);
            border-style: solid;
            border-width: 1px;
            color: black;
            font-size: 16px;
            letter-spacing: 0.3px;
            line-height: normal;
            margin-top: 30px;
            max-width: 100%;
            padding: 10px 25px;
            text-align: center;
            transition: $mo-ease;
            width: 100%;
            &:hover {
                background: black;
                border-color: white;
                color: white;
            }
            &.disabled {
                pointer-events: none;
            }
        }
        .t-810-secure {
            color: #f76c04;
            display: block;
            font-size: 14px;
            letter-spacing: 0.3px;
            line-height: 1.5;
            padding-top: 5px;
        }
    }
}
